﻿/*#region STYLING*/

//Preventing FOUC
.no-js {
    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }

    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

//Prevent transition on load
.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

*,
*:before,
*:after {
    transition: all .2s linear;
}

body {
    word-wrap: break-word;
}

h5 {
    text-align: center;
    margin-bottom: 30px;
    font-weight: $normal;
}

h1 {
    margin: rem-calc(30 0);
    text-transform: uppercase;
    text-align: center;

    @include breakpoint(small only) {
        margin-top: 10px;
    }
}

i {
    color: scale-color($body-font-color, $lightness: 60%);
}

a {
    i {
        color: $anchor-color;
    }

    &:hover i {
        color: $anchor-color-hover;
        transform: unset;
    }
}

.quote {
    border-left: 1px solid $dark-gray;
    font-style: italic;
    padding: rem-calc(5 10);
    font-size: 110%;
    color: $dark-gray;
    line-height: 1.3;
}

#pnlAlbum {
    margin-bottom: 20px;
}

.fa-asterisk {
    font-size: rem-calc(8) !important;
    color: red;
    vertical-align: middle !important;
}

.background-light {
    background-color: $light-gray;
    display: flex;
    /* justify-content: center; */
    align-items: center;

    .text {
        padding: 3rem;
    }
}

/*#endregion*/

/* #region ICONS FONT*/

i[class*='fa-'] {
    font-size: 1.2em;
}

/*#endregion */

/*#region BUTTON */

.button.hollow {
    &:hover {
        border-color: $button-background-hover;
        color: $button-background-hover;
    }
}

/*#endregion */

/* #region FORM*/

label {
    margin-bottom: 20px;

    table, select, &[for=txtWebSite], textarea {
        margin-bottom: 0;
    }
}

.input-group {
    margin-bottom: 0;
}

input:not(.button) {
    margin: 0 !important;
}

.fieldset, fieldset {
    background-color: $white;

    legend {
        font-weight: bold;

        @include breakpoint(medium) {
            font-size: rem-calc(20);
        }
    }
}

[id^=rfv], [id^=rev], [id^=cvl] {
    font-size: rem-calc(12);
    color: red;
}

#pnlCaptcha {
    margin: rem-calc(10) 0 rem-calc(20);
}

#txtWebSite {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.captcha {
    .dxca-imageDiv {
        background-color: $primary-color !important;
    }
}

#lnkPrivacy{
    font-weight: $bold;
    text-transform: uppercase;
}
/*#endregion*/

/*#region LOGIN FORM*/

#pnlLogin {
    #lnkPasswordRecovery {
        float: right;
        font-size: rem-calc(14);
        font-style: italic;
    }

    a.button {
        margin-top: 5px;
    }
}

/*#endregion*/

/*#region CALLOUT */
.callout {
    margin-top: 5px;
}
/*#endregion */

/*#region REVEAL */

.reveal {
    .close-button {
        right: 10px;
        top: -4px;
    }
}


/*#endregion */

/* #region MAPS*/

#pnlPlaces {
    margin-top: 10px;
}

.gm-style a img {
    max-width: 100px !important;
}

/*#endregion */

/*#region PANEL WITH ICON */

div[id^=pnl] {
    i {
        vertical-align: sub;
    }
}

/*#endregion */

/*#region SCROLL DOWN/UP*/
#buttonDown {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -23px;
    border: 2px solid $primary-color;
    background-color: rgba($white, 0.2);
    border-radius: 50px;
    box-sizing: border-box;
    cursor: pointer;

    $lighter-primary: scale-color($primary-color, $lightness: 20%);

    &:hover {
        border: 2px solid $lighter-primary;

        &::after {
            background-color: rgba( $lighter-primary, 0.7);
        }
    }

    &::after {
        position: absolute;
        top: 10px;
        left: 50%;
        content: '';
        width: 4px;
        height: 10px;
        margin-left: -3px;
        background-color: rgba($primary-color, 0.7);
        border-radius: 50px;
        box-sizing: border-box;
        -webkit-animation: scrolling 2s infinite;
        animation: scrolling 2s infinite;
    }
}

$buttonUp-color: white; //$anchor-color;
$buttonUp-color-hover: white;
$buttonUp-background: $primary-color;

#buttonUp {
    position: fixed;
    right: 5px;
    bottom: -100px;
    @include scroll-up;
    transition: bottom .5s;
}
/*#endregion*/

/*#region PREV-NEXT*/

#pnlTopNextPrev {
    .item {

        &-prev, &-next {
            position: relative;
            display: inline-block;

            &:before, &:after {
                font-size: 200%;

                @include breakpoint(medium) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            span {
                @include breakpoint(small only) {
                    display: none;
                }
            }
        }

        &-prev {

            @include breakpoint(medium) {
                padding-left: 20px;
            }



            &:before {
                content: "<";
                left: 0;
            }
        }

        &-next {
            @include breakpoint(medium) {
                padding-right: 20px;
            }



            &:after {
                content: ">";
                right: 0;
            }
        }
    }

    a {
        background-color: transparent;
        color: $anchor-color;
        font-size: 100%;
        height: inherit;

        &:hover {
            color: $anchor-color-hover;
        }
    }
}

#pnlTopNextPrev {
    font-size: 80%;
    padding: 20px 0;
}

#pnlBottomNextPrev {
    margin: 40px 0;

    .item {
        display: block;
        border: 1px solid $dark-gray;
        padding: 6px 20px;
        color: $black;
        position: relative;
        font-size: rem-calc(15);
        transition: all .5s;
        margin: 2px 0;


        &-prev, &-next {

            @include breakpoint(small only) {
                text-align: center;
                padding: 6px 20px !important;
            }

            @include breakpoint(medium) {
                display: inline-block;
            }


            &:before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: rem-calc(20px);
            }
        }

        &-prev {
            padding-left: 40px;
            transition: all .5s;

            &:before {
                content: "<";
                left: 5px;
            }
        }

        &-next {
            padding-right: 40px;

            &:before {
                content: ">";
                right: 5px;
            }
        }

        .labels {
            display: block;
            text-transform: uppercase;
            font-size: rem-calc(12);
            font-weight: $bold;
        }


        &:hover {
            background-color: scale-color($primary-color, $lightness:90%);

            @include breakpoint(medium) {
                &.item-prev {
                    padding-left: 55px;
                }

                &.item-next {
                    padding-right: 55px;
                }

                &.item-back {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }
}

/*#endregion */

/*#region BREADCRUMBS & SUBNAV*/

$secondaryNav-bg: scale-color($light-gray, $lightness:-3%);

#pnlSecondaryNavigation {
    position: relative;
    background-color: $secondaryNav-bg;

    .sticky {
        z-index: 999;

        &.is-at-top {
            background-color: $secondaryNav-bg;
        }
    }
}

/* #region BREADCRUMBS*/
$breadcrumbs-bg: transparent; //scale-color($secondary-color, $lightness: -5%);
$breadcrumbs-padding: 10px 0;
$breadcrumbs-margin: 0;
$breadcrumbs-font-size: 0.9em;

$breadcrumbs-font-color: $extradark-gray;
$breadcrumbs-anchor-color: $primary-color;
$breadcrumbs-current-item-color: $dark-gray;

#breadcrumbsContainer .breadcrumbs {
    @include custom-breadcrumbs();
    display: inline-block;
}

/*#endregion */
/*#region SUB NAV*/
$subNav-bg: transparent; //rgba($body-background, 0.99);
$subNav-padding: 0;

$subNav-item-padding: 13px 20px 17px;

$subNav-item-bg: transparent;
$subNav-item-color: $black;

$subNav-item-hover-bg: transparent;
$subNav-item-hover-color: $black;

$subNav-item-active-bg: $body-background;
$subNav-item-active-color: $dark-gray;


//close
$subNav-toggle-color: $black;
$subNav-toggle-bg: scale-color($secondaryNav-bg, $lightness:-5%);

$subNav-close-item-padding: 10px 20px 17px;
$subNav-close-item-margin: 5px 0;

$subNav-close-item-bg: transparent;
$subNav-close-item-color: $black;

$subNav-close-item-hover-bg: transparent;
$subNav-close-item-hover-color: $black;

$subNav-close-item-active-bg: $body-background;
$subNav-close-item-active-color: $dark-gray;


#pnlContainerSubNavMenu {
    background-color: $subNav-bg;

    .title-bar, .top-bar, .top-bar ul {
        background-color: transparent;
    }

    a {
        white-space: normal;
    }

    li {
        a {
            padding: $subNav-item-padding;
            background: $subNav-item-bg;
            color: $subNav-item-color;
            line-height: 1.6;

            span {
                padding-bottom: 3px;
                border-bottom: 1px dotted $subNav-item-color;
            }
        }

        &:hover {



            a {
                background-color: $subNav-item-hover-bg;
                color: $subNav-item-hover-color;

                span {
                    border-bottom-style: solid;
                }
            }
        }

        &.active {



            a {
                background-color: $subNav-item-active-bg;
                color: $subNav-item-active-color;

                span {
                    border-bottom: transparent;
                }
            }
        }
    }

    .toggle {
        color: $subNav-toggle-color;
        background-color: $subNav-toggle-bg;
        padding: $subNav-item-padding;
        display: inline-block;
        cursor: pointer;

        i {
            color: inherit;
            background-color: inherit;
            font-size: 70%;
            vertical-align: middle;
            padding: 0 6px;
        }
    }

    #pnlSubNavTopBar {
        padding: $subNav-padding;
    }

    #pnlSubNavTitleBar {
        padding: 0;
    }

    &.close, &.alwaysClose {
        align-self: stretch;

        #pnlSubNavTitleBar {
            align-items: stretch;
            height: 100%;
        }

        #pnlSubNavTopBar {
            position: absolute;
            width: 100%;
            top: 100%;
            left: 0;
            background-color: $subNav-toggle-bg;


            .top-bar-right {
                width: 100%;
            }

            .menu {
                max-width: $global-width;
                margin: auto;
                @extend %responsive-padding;
                max-height: 65vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        li {
            width: 100%;

            @include breakpoint(500px) {
                width: calc(100% / 2);
            }

            @include breakpoint(700px) {
                width: calc(100% / 3);
            }

            @include breakpoint(1200px) {
                width: calc(100% / 4);
            }

            a {
                padding: $subNav-close-item-padding;
                margin: $subNav-close-item-margin;
                background: $subNav-close-item-bg;
                color: $subNav-close-item-color;

                span {
                    padding-bottom: 4px;
                    border-bottom: 1px dotted $subNav-close-item-color;
                }
            }

            &:hover {

                a {
                    background-color: $subNav-close-item-hover-bg;
                    color: $subNav-item-hover-color;

                    span {
                        border-bottom-style: solid;
                    }
                }
            }

            &.active {

                a {
                    background-color: $subNav-close-item-active-bg;
                    color: $subNav-close-item-active-color;

                    span {
                        border-bottom: transparent;
                    }
                }
            }
        }

        .toggle {

            &.flex {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}
/*#endregion */
/*#endregion*/

/*#region FILTER*/

$filter-bg-color: scale-color($white, $lightness: -1%);

.filterMenu .menu {

    li, a {
        display: inline-block;
    }

    a {
        white-space: normal;
    }
}

#pnlFilterMenu {
    position: relative;


    @include breakpoint(small only) {
        //margin-bottom: 30px;
    }

    .filterMenuTitleBar {
        border: 1px solid $light-gray;
        border-radius: 20px;
        padding: 5px 8px;
        line-height: 0;
        background-color: $primary-color;
        color: $white;
        display: none;

        @include breakpoint(small only) {
            display: block;
            position: absolute;
            top: -60px;
            right: 10px;
        }

        div[data-toggle]:hover {
            cursor: pointer;
        }

        span {
            vertical-align: sub;
        }

        i {
            color: $white;
        }
    }

    .filterMenu {
        margin-bottom: 10px;
        /*overflow-x: hidden;*/
        @include breakpoint(small only) {
            width: 50%;
            position: absolute;
            top: -18px;
            background-color: $filter-bg-color;
            z-index: 9;
            right: 10px;
            @include box-shadow(0 0 5px rgba($black, 0.2));
            display: block;
            padding: 10px 0;

            &::before {

                @include css-triangle(12px, $filter-bg-color, up);
                position: absolute;
                top: -10px;
                right: 15px;
            }

            &::after {

                @include css-triangle(13px, rgba($light-gray, 0.5), up);
                position: absolute;
                top: -12px;
                right: 14px;
                z-index: -1;
            }
        }

        @include breakpoint(medium) {
            margin: 10px 0 30px;
        }

        ul {
            @include breakpoint(small only) {
                max-height: 65vh;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 15px;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        li {
            @include breakpoint(small only) {
                display: block;
                text-align: center;
                width: 100%;
            }

            @include breakpoint(medium) {
                margin: 0 5px 10px 0;
            }
        }

        a {
            padding: 5px 7px !important;

            @include breakpoint(small only) {
                margin-bottom: 5px;
                display: block;
                padding: 7px 0 !important;
            }

            @include breakpoint(medium) {
                font-size: $text-small;
                border: 1px solid $light-gray;
                border-radius: 20px;
            }

            &:hover, &.active {
                background-color: $primary-color;
                color: $white;
            }
        }
    }
}
/*#endregion*/

/* #region SHARING*/
.sharing {
    margin: 15px 0;

    a.button {
        margin-top: auto;
        margin-bottom: 0;
        padding: 0.5rem;
        font-size: 0.7rem;
    }


    .sharing-icon {
        font-size: 1.2rem;
        display: inline-block;
        margin: 0 4px;
        line-height: 1;

        @include breakpoint(small only) {
            margin: 0 2px;
        }
    }

    p {
        margin: 0;
        font-size: 75%;
        text-transform: uppercase;

        @include breakpoint(small only) {
            font-size: 65%;
        }

        @include breakpoint(medium only) {
            margin-top: 10px;
        }
    }
    /*@include breakpoint(medium only) {
        text-align: center;

        .cell {
            text-align: center;
        }
    }*/
}
/*#endregion */

/*#region ACCORDION FOLDERS */

$accordion-bg: $light-gray;
$accordion-sub-bg: $medium-gray;
$accordion-font-color: $body-font-color;
$accordion-icon-color: $extradark-gray;
$accordion-separator: $medium-gray;

#pnlLibrary {
    @include custom-accordion();
    margin-top: rem-calc(30);

    a[id^="lnkDoc"],
    a[id^="btnDoc"] {
        width: 100%;
        position: relative;
        margin: 0;

        span {
            line-height: 1.5;
        }

        i {
            position: absolute;
            right: rem-calc(15);
        }
    }

    .warning {
        background-color: $secondary-color;
        opacity: .5;

        .fa-download {
            &:before {
                content: "\f023";
            }
        }

        &:hover {
            background-color: scale-color($secondary-color, $lightness:-10%);
        }
    }
}
/*#endregion */

/*#region TABS */
.tabs-title {
    background: $light-gray;
}

.tabs {
    border-bottom: 0;
}
/*#endregion */
/*#region CARD */
.card-custom {
    $transition-delay: 0;
    $transition-delay-hover: .3s;

    @include box-shadow(0 0 6px 1px $medium-gray);
    border: none;
    position: relative;
    height: 100%;
    margin-bottom: 0;
    transition: all .6s;
    transition-delay: $transition-delay;
    overflow: hidden;
    background: $white;

    &:hover {
        @include box-shadow(0 0 10px 2px $extradark-gray);
        z-index: 99;
        transition-delay: $transition-delay-hover;
    }
    //block area
    .lnkItemWrapper {
        position: relative;
        display: block;

        .gridItemTitle, &:before, &:after, & + .bottom-box, img {
            transition-delay: $transition-delay;
        }

        &:before {
            /*overlay*/
            content: "";
            position: absolute;
            transition: all .8s linear;
            transition-delay: $transition-delay;
        }

        &:after {
            /*arrow icon*/
            content: "\f061 ";
            font-family: FontAwesome;
            position: absolute;
            top: -20%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            font-size: 3rem;
            z-index: 99;
            opacity: 0;
            transition: all 1s;
        }

        &:hover {
            .gridItemTitle, &:before, &:after, & + .bottom-box, img {
                transition-delay: $transition-delay-hover;
            }

            .gridItemTitle {
                z-index: 99;
                position: relative;
                color: $white;
            }

            & + .bottom-box {
                .pnlParent, .pnlPersons {
                    opacity: 0.3;
                }

                position: relative;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition-delay: $transition-delay-hover;
                    z-index: 9;
                }
            }
        }
    }

    .image-wrapper {
        border-bottom: 1px solid $light-gray;
        overflow: hidden;

        img {
            border-radius: 0;
            transition: all 0.5s;
        }
    }

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s;




        > div:last-child {
            margin-left: auto;
            margin-right: 0;
        }

        > div:first-child {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .bottom-box {
        margin-bottom: 0;
        margin-top: auto;


        &:before {
            content: "";
            transition: all .7s linear;
            transition-delay: $transition-delay;
        }

        .pnlParent, .pnlPersons {
            transition: all .8s linear;
        }

        &:hover {
            background-color: scale-color($light-gray, $lightness:-5%);
        }
    }
    //items
    .date {
        font-size: rem-calc(13);
        color: $body-font-color;
    }

    .gridItemTitle {
        font-size: rem-calc(20);
        font-weight: $bold;
        transition: all 1s;
    }

    .description, .location {
        margin-top: 10px;
        color: $body-font-color;
    }

    .location {
        font-weight: $normal;
        font-style: italic;
    }

    .pnlParent {
        font-style: italic;
        font-size: rem-calc(15px);
        font-weight: $normal;
        color: $body-font-color;

        a {
            font-weight: $normal;


            &:before {
                content: "#";
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .pnlOrganizations {
        a {
            font-weight: $normal;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .pnlPersons {

        .personItemList {
            display: inline-block;
            position: relative;

            a {
                display: inline-block;
            }

            &:hover {
                .personName {
                    display: block;
                    position: absolute;
                    bottom: 100%;
                    background-color: $light-gray;
                    padding: 10px 5px;
                    font-size: rem-calc(12);
                    text-transform: uppercase;
                    @include box-shadow(0 0 4px 1px $dark-gray);
                    text-align: center;
                    left: 50%;
                    transform: translateX(-50%);

                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 20px 20px 0 20px;
                        border-color: $light-gray transparent transparent transparent;
                        position: absolute;
                        top: calc(100% - 10px);
                        left: calc(50% - 20px);
                        display: block;
                    }
                }
            }
        }

        img {
            @include circleAvatar(50px);
        }

        .personName {
            display: none;
            z-index: 9;
            color: $anchor-color;

            &:hover {
                color: $anchor-color-hover;
            }
        }
    }

    .pnlServicesProducts {
        border-top: 1px solid $light-gray;
    }

    .pnlProducts, .pnlServices {
        margin: 10px 0;

        .gridItemTitle {
            font-size: rem-calc(14);
            margin-bottom: 6px;
            font-weight: $bold;
        }

        .item {
            text-transform: uppercase;
            font-size: rem-calc(12);

            a {
                background-color: $button-background;
                color: $button-color;
                margin: 0 6px 6px 0;
                display: inline-block;
                padding: 2px 4px;
                border-radius: $global-radius;

                &:hover {
                    background-color: $button-background-hover;
                }
            }

            &:not(.hasLink):not(:last-child):after {
                content: "-";
            }
        }
    }
    //vertical layout
    &.vertical {
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: column;

        .lnkItemWrapper {
            flex-grow: 1;
            /*display: flex;
            flex-direction: column;
            flex: 1;*/
        }
    }
    //main hover effect
    .primary-overlay {
        &:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &:hover {
            &:before {
                display: block;
                background-color: rgba($primary-color, 0.9);
                z-index: 9;
            }

            &:after {
                top: 20%;
                opacity: 1;
            }

            img {
                filter: grayscale(1);
            }

            & + .bottom-box {
                &:before {
                    background-color: rgba($primary-color, 0.9);
                }
            }
        }
    }

    .circle-arrow {

        &:after {
            background-color: $primary-color;
            font-size: 2.5rem;
            padding: 10px;
            border-radius: 50%;
            top: auto;
            bottom: -30%;
            left: calc(50% - 50px);
            transform: rotate(-270deg);
            width: 80px;
            height: 80px;
            text-align: center;
            @include box-shadow (0 0 0 15px rgba($primary-color, 0.6));
        }

        &:hover {
            &:before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                display: block;
                background-color: rgba($black, 0.3);
                opacity: 1;
                z-index: 9;
            }

            &:after {
                bottom: 10%;
                opacity: 1;
                /*animation: spin .5s linear 1;
                animation-delay: .6s;*/
                transform: rotate(0);
            }

            & + .bottom-box {
                &:before {
                    background-color: rgba($black, 0.3);
                }
            }


            @extend .zoom-image;
        }
    }

    .slide-in-arrow {
        &:after {
            top: auto;
            bottom: 10%;
            right: 120%;
            left: auto;
            transition: right .7s ease-out;
        }



        &:before {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: all .7s ease-out;
        }

        &:hover {
            &:after {
                right: 10px;
                opacity: 1;
            }

            &:before {
                opacity: 0;
                display: block;
                background-color: rgba($primary-color, 0.7);
                opacity: 1;
                z-index: 9;
            }

            & + .bottom-box {
                &:before {
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba($primary-color, 0.7);
                }
            }
        }
    }

    .arrow-after-title {

        .gridItemTitle {
            position: relative;
            transition: all 0.2s;

            &:after {
                content: "\f061 ";
                font-family: FontAwesome;
                opacity: 0;
                transition: all 1s;
                position: absolute;
                right: 50px;
                transition-delay: $transition-delay;
            }
        }

        &:hover {
            @extend .zoom-image;

            &:before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                display: block;
                background-color: rgba($black, 0.1);
                opacity: 1;
                z-index: 9;
            }

            .gridItemTitle {
                color: scale-color($primary-color, $lightness: 20%);
                //text-align: left;
                &:after {
                    opacity: 1;
                    right: -2px;
                    transition-delay: $transition-delay-hover;
                }
            }

            & + .bottom-box {
                &:before {
                    background-color: rgba($black, 0.1);
                }
            }
        }
    }

    .popsup {
        &.lnkItemWrapper {
            transition: all 1s;
            transition-delay: $transition-delay;

            &:hover {
                background-color: rgba($primary-color, .1);
                transition-delay: $transition-delay-hover !important;

                .gridItemTitle {
                    color: $primary-color;
                }
            }
        }
    }

    .material-like {
        &:before {
            @include position($position:absolute, $center:true);
            width: 0;
            height: 0;
            border-radius: 50%;
            background-color: rgba($primary-color, .4);
            z-index: 9;
            transition: all .6s;
        }

        .gridItemTitle {
            transition: all .6s;
        }

        &:hover {
            &:before {
                width: 500%;
                padding-bottom: 500%;
            }
        }
    }
    //entity change
    .articlesGrid & {
        .date {
            position: absolute;
            top: 5px;
            right: 5px;
            background: rgba($white, 0.8);
            padding: 10px;
            font-weight: $normal;
            z-index: 9;
        }


        .flex {
            .pnlParent {
                margin-left: 0;
                margin-right: auto;
            }

            .pnlPersons {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .worksGrid & {
        .description {
            margin-top: 30px;
        }
    }

    .eventsGrid & {
        .row.collapse {
            margin: 0 !important;
            height: 100%;
            display: flex;
        }

        .leftCol, .card-divider {
            background: $dark-gray;
        }

        .image-wrapper {
            position: relative;
            border: none;

            img {
                filter: invert(5%);
            }
        }

        .date {
            color: $white;
            font-size: rem-calc(20);
            font-weight: $bold;
            text-align: center;
            line-height: 1.2;
            margin: auto;

            span {
                display: block;
                margin-bottom: 6px;
            }

            .time {
                font-size: 80%;
                line-height: normal;
                font-weight: $normal;
            }
        }

        .location {
            display: table;

            i {
                display: table-cell;
                padding-right: 3px;
                color: $dark-gray;
            }
        }

        .lnkItemWrapper {
            height: 100%;
            display: flex;
        }
    }

    .productsGrid &, .servicesGrid & {
        .flex {
            .pnlOrganizations {
                margin-left: 0;
                margin-right: auto;
            }

            .pnlPersons {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .demandsGrid & {


        .lnkItemWrapper {
            display: flex;
            flex-direction: column;
        }

        .card-section {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .readMore {
            margin-bottom: 0;
            margin-top: auto;
        }
    }

    .jobPostingsGrid & {
        text-align: left;

        * {
            text-align: inherit;
        }
    }
}
/*#endregion */
/*#region BLOCK GRID*/
.grid-x[class*="-up-"] > .cell {
    @include get-map-value(margin-bottom, $grid-column-gutter);

    img {
        width: 100%;
    }
}

.gridItemTitle {
    font-size: rem-calc(20);
    margin-bottom: 2px;
    line-height: 1.4;
}
/*#endregion*/
/*#region LEAF PAGE - main content + relationships */
#pnlMain {
    margin-bottom: rem-calc(30);
}

.stripe {
    padding: 50px 0;

    .grid-x[class*="-up-"] {
        @include flex-align($x:center);
    }

    &.even {
        background-color: scale-color($body-background, $lightness:-2%);
    }
}
/*#endregion */
