/*#region Typography*/

.bold {
    font-weight: $bold;
}

.light {
    font-weight: $light;
}

.text-tiny {
    font-size: $text-tiny;
}

.text-small {
    font-size: $text-small;
}

.text-large {
    font-size: $text-large;
}

.text-xlarge {
    font-size: $text-xlarge;
}

.text-huge {
    font-size: $text-huge;
    line-height: rem-calc(36);
}

/*#endregion*/

/*#region Display*/

.inline-block {
    display: inline-block;
}

.block {
    display: block;
}

/*#endregion*/

/*#region Margin&Padding*/

.min-padding-right {
    @include breakpoint(medium) {
        padding-right: rem-calc(3);
    }
}

.min-padding-left {
    @include breakpoint(medium) {
        padding-left: rem-calc(3);
    }
}

//margin negative (based on gutter value) to align grid - responsive
%responsive-margin {
    $margin: rem-calc(map-get($grid-column-gutter, small));
    margin-left: -($margin / 2) !important;
    margin-right: -($margin / 2) !important;

    @include breakpoint(medium) {
        $margin: rem-calc(map-get($grid-column-gutter, medium));
        margin-left: -($margin / 2) !important;
        margin-right: -($margin / 2) !important;
    }
}


//padding (based on gutter value) - responsive
%responsive-padding {
    $padding: rem-calc(map-get($grid-margin-gutters, small));
    padding-left: ($padding / 2);
    padding-right: ($padding / 2);

    @include breakpoint(medium) {
        $padding: rem-calc(map-get($grid-margin-gutters, medium));
        padding-left: ($padding / 2);
        padding-right: ($padding / 2);
    }
}



/*#endregion*/

/*#region Styling*/

%date-with-icon {
    .date {
        font-size: 90%;
        margin-bottom: rem-calc(5);
        display: inline-block;

        .fa-calendar {
            margin-right: rem-calc(5);
            vertical-align: sub;
        }
    }
}

%unset-min-height {
    height: unset;
    width: 90%;
    margin: auto;
    min-height: unset !important;
}

.no-border {
    border: 0 !important;
}

.separator {
    @include breakpoint(medium) {
        border-right: rem-calc(1) solid $light-gray;
    }
}

.square {
    overflow: hidden;
}

/*#endregion*/

/*#region Layout*/

//BUG FIX - not edit
/*.grid-container-padded {

    @extend %responsive-padding;
    padding-left: 0.625rem;
    padding-right: 0.625rem;


    @include breakpoint($grid-container-padding) {
        padding-left: 0;
        padding-right: 0;
    }
}*/

.center-block-grid {
    .grid-x[class*="-up-"] {
        @include flex-align($x:center);
    }
}

/*#endregion*/

/*#region Visibility*/

.not-display-small {
    @include breakpoint(small only) {
        display: none !important;
    }
}

/*#endregion*/


