﻿/* #region IMAGE & IMAGE OVERLAY */

img {
    border-radius: $global-radius;
}

.overlay-fade-in {

    &.image-wrapper {
        width: 100%;
        position: relative;
        text-align: center;
        border-radius: $global-radius;
        display: inline-block;
    }

    .image-overlay-content {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        border-radius: $global-radius;
        opacity: 0;
        background-color: rgba($secondary-color, 0.9);
        @include single-transition(all, 0.4s, ease-in-out, 0s);
        border: rem-calc(3) solid scale-color($secondary-color, $lightness: -30%);
    }

    p {
        opacity: 0;
        @include single-transition(all, 0.2s, ease-in-out, 0s);
        text-transform: uppercase;
        padding: rem-calc(5);
        font-size: 1em;
        color: $primary-color;
        margin: 0;
        position: absolute;
        top: -rem-calc(200);
        width: 100%;
    }

    &:hover {
        .image-overlay-content, p {
            opacity: 1;
            top: 0;
        }
    }
}

.overlay-details {
    &.image-wrapper {
        position: relative;
        text-align: center;
        transition: all 0.5s;
    }

    .image-overlay-content {
        position: absolute;
        overflow: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $global-radius;
        transition: all 0.5s;
    }

    img {
        transition: all 0.7s;
    }

    .text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: rem-calc(4 7);
        background-color: rgba($black, 0.45);
        color: white;
        padding: rem-calc(5);
        transition: all 0.5s;



        p {
            margin-bottom: 0;
        }

        .button {
            color: white;
            border-color: white;
            padding: rem-calc(5 10);
            margin: rem-calc(10 0 5);




            &:hover {
                color: white;
                border-color: white;
            }
        }
    }

    .gridItemTitle {
        font-weight: $bold;
        font-size: rem-calc(17);
    }

    .description {
        font-size: rem-calc(14);
    }

    &:hover {
        @include box-shadow(0 0 rem-calc(10) rem-calc(2) $extradark-gray);

        .text {
            margin-bottom: rem-calc(10);
            background-color: rgba($primary-color, 0.9);
        }

        img {
            filter: grayscale(1);
        }

        .button {
            display: inline-block;
            opacity: 1;
        }
    }
}

.blur {

    &.image-wrapper {
        width: 100%;
        position: relative;
        text-align: center;
        border-radius: $global-radius;
        display: inline-block;
    }

    .image-overlay-content {
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: $global-radius;
        background-color: rgba($primary-color, 0.5);
        @include single-transition(all, 0.6s, ease-in-out, 0s);
        opacity: 0;

        &:before {
            content: "\f061 ";
            font-family: FontAwesome;
            position: absolute;
            bottom: -20%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            font-size: 2rem;
            z-index: 99;
            opacity: 0;
            @include single-transition(all, 0.6s, linear, 0s);
        }
    }

    img {
        @include single-transition(all, 0.4s, ease-in-out, 0s);
    }

    .text {
        opacity: 0;
        padding: rem-calc(5);
        color: $white;
        margin: 0;
        position: absolute;
        top: -20%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        @include single-transition(all, 0.6s, linear, 0s);
    }

    .gridItemTitle {
        font-size: rem-calc(18);
        font-weight: $bold;
        text-transform: uppercase;
    }

    .pnlParent {
        font-size: rem-calc(14);
        font-weight: $normal;
        margin-bottom: 1%;
        font-style: italic;
    }

    .description {
        margin-top: 6%;
        font-size: rem-calc(15);
    }

    &:hover {

        .image-overlay-content {
            opacity: 1;
            top: 0;

            &:before {
                opacity: 1;
                bottom: 5%;
            }
        }

        .text {
            opacity: 1;
            top: 10%;
        }

        img {
            filter: blur(5px) grayscale(1);
            opacity: 0.6;
        }
    }
}

.zoom-image {

    img {
        transform: scale(1.2);
    }
}

.highlight {
    .image-overlay-content {
        overflow: hidden;
        @include position($position:absolute, $center:true);
        opacity: 0;
        font-weight: $bold;
        background-color: rgba($light-gray, .5);
        width: 100%;
        height: 100%;

        .text {
            @include position($position:absolute, $center:true);
            text-align: center;
        }
    }

    &:hover {
        box-shadow: 0 0 6px 0 $medium-gray;

        img {
            opacity: 0;
        }

        .image-overlay-content {
            opacity: 1;
        }
    }
}


/*#endregion */

/*#region CAROUSEL */
.slick-slider {
    clear: both;
    margin-bottom: 0;

    &, *{
        transition:unset;
    }

    .slick-dots {
        position: inherit;
        margin: 0 auto;
        max-width: 73.75rem;
        width: 100%;
        bottom: inherit;
    }

    .slick-arrow {
        z-index: 999;

        &.slick-prev {
            left: rem-calc(15);

            &:before {
                color: $black;
            }
        }

        &.slick-next {
            right: rem-calc(15);
        }

        &.slick-prev, &.slick-next {
            &:before {
                color: $black;
                font-size: rem-calc(25);
            }
        }
    }

    .slideBackground {
        position: absolute;
        bottom: rem-calc(20);
        width: 100%;
        padding: rem-calc(0 5);
        background: rgba($dark-gray, 0.7 );

        h2, p, a {
            color: scale-color($secondary-color, $lightness: 30%);
        }

        a {
            display: table;
            padding: rem-calc(5);
            border: rem-calc(1) solid scale-color($secondary-color, $lightness: 30%);
            margin: rem-calc(10) auto;
            border-radius: $global-radius;

            &:hover {
                color: $dark-gray;
                background-color: rgba(scale-color($secondary-color, $lightness: 30%), 0.6);
            }
        }
    }
}

/*#endregion */

/*#region LIGHTBOX */
$lightbox-item-gutter: rem-calc(5);

.zoom-gallery {

    .cell {
        /*padding-right: $lightbox-item-gutter !important;*/
        margin-bottom: $lightbox-item-gutter;
    }
}

.hybrid-gallery {
    img {
        cursor: pointer;
    }
}

/*#endregion */

