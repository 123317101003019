﻿/* #region ARTICLE(S)*/

.article {

    #pnlAlbum {
        margin-bottom: 15px;
    }

    #pnlRightCol {
        @include breakpoint(small only) {
            border: 1px solid $secondary-color;
            padding: 0px 10px;
            margin-bottom: 10px;
            font-size: 90%;
        }

        #imgPublisherLogo {
            margin-left: 25px;
            max-width: 110px;
        }
    }
}

/*#endregion */

/*#region EVENT (S) */

.event {

    #pnlDate, #pnlCountdown {
        padding: 10px 0;
        display: flex;
        align-items: center;

        i {
            margin-right: 15px;
        }

        .date {
            font-size: rem-calc(18);
        }

        .timeValue {
            font-size: rem-calc(15);
            display: block;
            color: scale-color($body-font-color, $lightness: 50%);
        }
    }

    #pnlDate {
        padding-top: 0;
    }
}

.eventsGrid {
    .card-section {
        padding: rem-calc(16) rem-calc(5) rem-calc(12) rem-calc(10);
    }
}
/*#endregion */

/*#region PERSON(S) */
.person {

    @include breakpoint(small only) {
        font-size: 90%;
        margin-bottom: 10px;
    }

    #pnlRightCol {
        i[class*='fa-'] {
            width: 25px;
            vertical-align: baseline;
            margin-bottom: 10px;
        }
    }

    a {
        i[class*='fa-'] {
            color: $anchor-color;
        }

        &:hover [class*='fa-'] {
            color: $anchor-color-hover;
        }
    }

    .fa-calendar {
        margin-right: 7px;
    }
}

/*#endregion */

/* #region WHERE WE ARE*/

.whereWeAre {

    #requestInfoContactPage {

        @include breakpoint(small only) {
            margin-bottom: 20px;
        }

        fieldset {
            border: none;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
        }

        legend {
            @include header-size(h3);
            line-height: $header-lineheight;
            color: $header-color;
            font-family: $header-font-family;
            font-style: $header-font-style;
            font-weight: $global-weight-normal;
            margin-top: 10px;
        }
    }

    .company {
        padding: 10px 30px;
        margin: 5px 0;

        i {
            vertical-align: middle;
            margin-right: 5px;
            font-size: 1.3rem;
        }

        .blockTitle {
            font-weight: bold;
            display: block;
        }

        .label {
            font-weight: $bold;
            font-size: 90%;
            background-color: unset;
            color: $body-font-color;
            padding: 0;
            text-transform: uppercase;
        }

        i {
            font-size: 1.2em;
        }

        a {
            margin-bottom: 10px;
            display: block;
        }

        .inline-block {
            margin-bottom: 10px;
        }

        .contactPoint {
            margin-top: 20px;
        }

        &:nth-child(odd) {
            background-color: rgba($light-gray, 0.7);
        }

        &:nth-child(even) {
            background-color: rgba($light-gray, 0.3);
        }
    }

    .contactMap + .breadcrumbsContainer {
        margin-top: 0;
    }

    .contactMap {
        margin-top: 10px;
    }

    #pnlDistributor {
        margin: rem-calc(5) 0;
        //padding: rem-calc(10) 0;
        //background-color: rgba($light-gray, 0.3);
        .organizationslist {
            /*padding: rem-calc(20) rem-calc(20);*/
            .legalName {
                font-size: rem-calc(18);
                text-align: left;
                background-color: $primary-color;
                color: $light-gray;
                padding: rem-calc(5) rem-calc(20);
                font-weight: $bold;
                margin: rem-calc(20) 0;
            }

            .singleOrg {
                margin-bottom: rem-calc(20);

                div {
                    padding-left: rem-calc(10);
                    padding-left: rem-calc(10);
                }

                #pnlNameDistributor {
                    font-size: rem-calc(16);
                }

                #pnlSite, #pnlEmail, #pnlNameDistributor {
                    font-weight: $bold;
                }
            }
        }

        h4 {
            padding: 0 rem-calc(20) 0;
            font-size: rem-calc(24);
            font-weight: $bold;
        }
    }
}

/*#endregion */

/*#region SERP */

.serp {
    .sticky-menu {
        @extend %responsive-padding;


        @include breakpoint(medium) {
            font-size: rem-calc(20);
        }

        padding-top: 10px;

        .menu {
            li {
                margin: 4px 0;

                &:first-child {
                    margin-bottom: 10px;
                }
            }

            a {
                padding-top: 6px;
                padding-bottom: 6px;


                &.is-active {
                    background-color: $primary-color;
                    color: $white;
                }
            }
        }
    }

    h5 {
        text-align: left;
        font-weight: $normal;
        font-size: rem-calc(30);
    }

    .panel {
        padding: 30px 0;

        @include breakpoint(medium) {
            &:first-child {
                padding: 0;
            }
        }
    }

    .grid-x[class*="-up-"] {
        @include flex-align($x:left);
    }

    .results {
        .panel:not(:last-child) {
            border-bottom: 1px solid $medium-gray;
        }
    }
}


/*#endregion */

/*#region ERROR */

.error {


    h1 {
        text-align: center;
        font-size: rem-calc(70);

        @include breakpoint(medium) {
            font-size: rem-calc(100);
        }
    }

    .text-large {
        margin-bottom: 4px;
        font-size: rem-calc(24);

        @include breakpoint(medium) {
            font-size: rem-calc(30);
        }
    }

    .text-small {
        font-size: rem-calc(16);
    }




    #pnlSolutions {
        font-size: rem-calc(20);
        margin: 40px 0;

        p {
            margin-bottom: 6px;
        }

        .button {
            margin: 6px;
            font-weight: $normal;

            @include breakpoint(medium) {
                font-size: rem-calc(20);
                margin: 10px;
            }
        }

        #lnkHome {
            i {
                color: inherit;
            }
        }

        i {
            padding-left: 5px;
            vertical-align: baseline;
        }
    }
}
/*#endregion */

/*#region JOB POSTING */

.jobPostings {
    #pnlBirthdate {
        label {
            line-height: 1;
        }

        .fa-asterisk {
            vertical-align: sub !important;
        }

        .not-mandatory {
            color: transparent !important;
        }
    }
}

.jobPostingsGrid {
    .date {
        display: block;
        font-size: rem-calc(15);
        color: scale-color($body-font-color, $lightness: 50%);
    }
}

.jobPosting {
    .row {
        margin-bottom: 18px;

        p, ul {
            margin-bottom: 0;
        }

        .bold {
            color: scale-color($body-font-color, $lightness: 40%);
            display: block;
            background-color: $light-gray;
            text-transform: uppercase;
            font-size: rem-calc(13);
            padding: 2px 5px;
            margin-left: -5px;
            margin-bottom: 2px;
            font-weight: $normal;
        }

        h3 {
            font-size: rem-calc(17);
            font-weight: $normal;
            margin: 10px 0 0;
        }
    }

    .formJobPosting {
        margin-top: rem-calc(20);
    }
}

.formApplication {
    #aspxChkSkils {


        label {
            width: calc(100% - 13px - 1.5rem);
        }
    }

    #pnlUpload {
        padding: 10px;
        border: 1px solid $medium-gray;
        margin-bottom: 40px;

        .token {
            padding: 5px;
            margin: 2px;
            border-radius: 7px;
        }

        table {
            margin-bottom: 0;

            tbody {
                tr {

                    &:nth-child(even) {
                        background-color: initial;
                    }
                }


                &:nth-child(odd) {
                    background-color: initial;
                }
            }

            td {
                padding: initial;
            }

            td a {
                padding: 10px;
                display: block;
            }
        }

        .button.secondary {
            font-size: rem-calc(18);
            border: 1px solid $medium-gray;
        }
    }


    #SubmitButton {
        padding: 4px 15px;
        font-size: rem-calc(16);
        margin-right: 0;
        float: right;
    }
}

/*#endregion */


/*CUSTOM*/


.productsGrid {
}

.articlesGrid {
    .image-wrapper {
        @include image-fixed-ratio(16, 9);
    }
}

.organizationsGrid {
    .image-wrapper {
        border: 1px solid $light-gray;

        @include image-fixed-ratio(4, 3, $fit: contain);

        img {
            padding: .3rem;
        }
    }
}

.homepage {
    #menu-section {
        @include breakpoint(medium) {
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        #pnlMenuMain {
            background-color: rgba($light-gray, 0.7);
        }
    }

    #pnlHomeSlider {
        .slideBackground {
            background-color: unset;
            text-align: left !important;
            @include xy-grid-container;
            padding: rem-calc(50);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    #pnlBody {

        .text {
            font-size: rem-calc(22);
        }
    }

    .productsGrid, .worksGrid {
        .image-wrapper {
            @include image-fixed-ratio(16, 9, $fit:contain);
        }
    }
}

.productsGrid, .worksGrid {
    .image-wrapper {
        @include image-fixed-ratio(16, 9, $fit:contain);
    }
}

.whoWeAres {
    #pnlAlbum {
        margin: 0;
    }
}

.products, .product.level-1, .services, .works, .demands {

    .firstWrapper {
        position: relative;

        .wrapperMenu {

            @include breakpoint(medium) {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                z-index: 1;
            }

            #menu-section {
                opacity: 0.6;
            }

            #pnlSecondaryNavigation {
                opacity: 0.7;

                .breadcrumbs {
                    padding: rem-calc(2) 0;
                }
            }
        }
    }

    #pnlImgCover {
        img {
            width: 100%;
        }
    }
}

.no-banner {
    .wrapperMenu {
        position: relative !important;

        #menu-section {
            opacity: 1 !important;
        }

        #pnlSecondaryNavigation {
            opacity: 1 !important;
        }
    }
}

.product {
    .pnlImageCover {
        text-align: center;
        margin: auto;
        margin-top: rem-calc(-30);
        margin-bottom: rem-calc(30);

        img {
            max-width: rem-calc(80);
        }
    }

    &.level-1 {

        .productsGrid {
            justify-content: center;
        }

        .image-wrapper {
            @include image-fixed-ratio(1, 1);

            img {
                padding: rem-calc(20);
            }
        }
    }

    .productsGrid {
        .leaf {
            .image-wrapper {
                @include image-fixed-ratio(16, 9, $fit:contain);
            }
        }
    }
}

.demand {
    .demandsGrid {
        .card-section {
            justify-content: center;
        }
    }
}

#pnlProductSlider, #pnlProductSlider1 {
    height: auto;
    background-color: transparent;
}

#pnlProductSlider1 {
    /*.slick-track {
        @include image-fixed-ratio(16, 9, $fit:contain);
    }*/
    margin-bottom: rem-calc(30);

    img {
        padding-right: rem-calc(10);
    }
}

#pnlProductSlider {
    .slick-list {
        max-width: 70%;
    }
}

.organization {
    #pnlRightCol {
        #pnlAlbum {
            img {
                max-width: 70%;
            }
        }
    }
}


//bug fix test
#pnlPlaces {
    transition: unset !important;

    &,
    &:before,
    &:after,
    *,
    *:before,
    *:after {
        transition: unset !important;
    }
}
