﻿/*#region FOOTER MAIN */

$footer-main-bg-color: $secondary-color;
$footer-main-text-color: $medium-gray;
$footer-main-anchor-color: $anchor-color;
$footer-main-anchor-color-hover: scale-color($anchor-color, $lightness:30%);

/*#endregion */

/*#region FOOTER COPYRIGHT */

$footer-copyright-bg-color: $white;//$primary-color;
$footer-copyright-text-color: $medium-gray;//$secondary-color;
$footer-copyright-anchor-color: scale-color($anchor-color, $lightness:-10%);
$footer-copyright-anchor-color-hover: $anchor-color;//white;

/*#endregion */

#footer {
    position: relative;

    .footer-main {
        background-color: $footer-main-bg-color;
        color: $footer-main-text-color;
        padding: 30px 0;

        a, a i {
            color: $footer-main-anchor-color;
        }

        a {
            color: $footer-main-anchor-color;

            &:hover, &:hover i {
                color: $footer-main-anchor-color-hover;
            }
        }

        .contacts {
            span {
                display: inline-block;
            }

            a {
                display: block;
                padding: 8px 0;
            }

            .middle-align {
                vertical-align: middle;
            }

            .span-i {
                width: 30px;
                vertical-align: middle;


                i {
                    font-size: 1.5em;
                    vertical-align: middle;
                }


                i.fa-envelope {
                    font-size: 1.3em;
                }
            }
        }

        .logo {
            margin: 10px 0;
            padding: 0 5px;

            img {
                max-width: 150px;
                width: 100%;

                @include breakpoint(medium only) {
                    max-width: 100px;
                }
            }
        }

        #pnlSocialMediaIconBar {
            margin-top: 10px;

            i[class*='fa-'] {
                font-size: 2.1em;
                padding: 0 10px;
                color: $footer-main-anchor-color;

                @include breakpoint(medium only) {
                    font-size: 1.7em;
                    padding: 0 4px;
                }

                &:hover {
                    color: $footer-main-anchor-color-hover;
                }
            }
        }

        .privacy-cookies {
            text-align: right;

            span {
                padding: 7px 0;
                display: inline-block;

                a {
                    display: block;
                    font-size: 80%;
                }
            }

            #lnkLogin {
                font-size: 100%;
                margin-top: 15px;
                display: block;


                i {
                    font-size: 1.3em;
                }
            }
        }

        @include breakpoint(small only) {
            padding: 10px 0;

            #pnlSocialMediaIconBar, .contacts, .privacy-cookies {
                text-align: center;
            }

            .contacts {

                a {
                    padding: 0;
                    margin-bottom: 10px;
                }

                span {
                    display: block;
                }

                .span-i {
                    display: inline-block;
                    width: 0;
                    margin-left: -15px;

                    i {
                        font-size: 1.3em;
                        line-height: 0;
                    }
                }
            }

            .logo {
                margin: 5px 0 20px;
            }

            #pnlSocialMediaIconBar {
                margin-bottom: 15px;


                [class*='fa-'] {
                    font-size: 2em;
                }
            }

            .privacy-cookies {

                span {
                    border-top: 1px solid scale-color($secondary-color, $lightness: -20%);
                    border-bottom: 1px solid scale-color($secondary-color, $lightness: -20%);
                    padding: 5px 20px;
                }
            }
        }

        @include breakpoint(medium only) {
            .contacts {

                a {
                    font-size: 90%;
                }

                .span-i {
                    width: 18px;

                    i {
                        font-size: 1.5em;
                    }
                }
            }
        }
    }

    .footer-copyright {
        background-color: $footer-copyright-bg-color;
        color: $footer-copyright-text-color;
        padding: 10px 0;

        p, a {
            font-size: 90%;
            margin: 0;
        }

        p {
            color: $footer-copyright-text-color;
        }

        a {
            font-weight: bold;
            color: $footer-copyright-anchor-color;

            &:hover {
                color: $footer-copyright-anchor-color-hover;
            }
        }

        @include breakpoint(small only) {
            p strong {
                display: block;
            }
        }

        @include breakpoint(medium only) {
            p {
                font-size: 85%;
            }
        }

        #pnlCredits {
            p {
                font-size: 80%;
            }
        }
    }
}

/*#region FOOTER MENU*/

/*#region Variabili Top Bar */

$topbar-background: transparent; //$primary-color;
$topbar-padding: 30px 0 0;

$menu-item-padding: 1px 0;
$menu-item-margin: 0 0.5rem;

$menu-item-font-size: rem-calc(11);
$menu-item-text-transform: uppercase;
$menu-item-font-weight: normal;

$menu-item-color: $primary-color;
$menu-item-background: transparent;

$menu-item-color-hover: scale-color($primary-color, $lightness:30%);
$menu-item-background-hover: transparent;
$menu-item-hover-underline: true;

$menu-item-color-active: scale-color($primary-color, $lightness:30%);
$menu-item-background-active: transparent;
$menu-item-font-weight-active: 400;
$menu-item-active-underline: true;

$menu-item-color-active-hover: scale-color($primary-color, $lightness:30%);
$menu-item-background-active-hover: transparent;

/*#endregion */

#pnlFooterMenu {

    @include custom-menu;

    .top-bar {
        @include top-bar-container;
    }
}


/*#endregion*/
