﻿//Usare le variabili qui presenti per settare le impostazioni dei vari menu (utility/main e small).
//le variabili prevedono degli stili base (sottolineatura, sfondo colorato, margini, padding, font-size etc);
//se si desidera un effetto particolare, aggiungere regole in fondo

/*#region MENU UTILITY */

/*#region Variabili Top Bar */

$topbar-background: $primary-color; //$light-gray;
$background-extended: true;
$topbar-padding: 0.3rem 0;

$menu-item-padding: 0; //adjust margin/padding if active is underline
$menu-item-margin: 0 0.5rem;

$menu-item-font-size: rem-calc(12);
$menu-item-text-transform: uppercase;
$menu-item-font-weight: normal;

$menu-item-color: rgba($white, 0.6); //$primary-color;
$menu-item-background: transparent;

$menu-item-color-hover: $white;
$menu-item-background-hover: transparent;
$menu-item-hover-underline: false;

$menu-item-color-active: $white; //scale-color($primary-color, $lightness:30%);
$menu-item-background-active: transparent;
$menu-item-font-weight-active: $bold;
$menu-item-active-underline: false;

$menu-item-color-active-hover: $white;
$menu-item-background-active-hover: transparent;

/*#endregion */

/*#region Variabili Dropdown (esempio: lingua)*/
$dropdown-background: $primary-color;
$dropdown-border: none;
$dropdown-min-width: unset;

$dropdown-position-x: 10px;
$dropdown-position-y: 140%;

$dropdown-font-size: rem-calc(14);

$dropdown-item-padding: 3px 0;
$dropdown-item-margin: 5px 10px;

$dropdown-item-color: $white;
$dropdown-item-background-color: transparent;

$dropdown-item-color-hover: $white;
$dropdown-item-background-hover: transparent;

$dropdown-item-color-active: $white;
$dropdown-item-background-active: transparent;

$dropdown-item-color-active-hover: $white;
$dropdown-item-background-active-hover: transparent;

$dropdown-parent-item-arrows: false;
$dropdown-item-arrows: true;

$dropdown-shadow: true;
/*#endregion */

#pnlMenuUtility {
    @include custom-menu;
    @include dropdown-medium-large;

    .top-bar {

        @include top-bar-container;
    }
}

/*#endregion */

/*#region MENU MAIN */

/*#region Variabili Top Bar */

$topbar-background: transparent;
$background-extended: true;
$topbar-padding: 0.5rem 0;

$menu-item-padding: 0.3rem 0.5rem 1rem; //swap&adjust margin/padding if active is underline
$menu-item-margin: 0.3rem 0.5rem;

$menu-item-font-size: rem-calc(18);
$menu-item-text-transform: uppercase;
$menu-item-font-weight: 400;

$menu-item-color: $primary-color;
$menu-item-background: transparent;

$menu-item-color-hover: $light-gray;
$menu-item-background-hover: $primary-color;
$menu-item-hover-underline: true;

$menu-item-color-active: $light-gray;
$menu-item-background-active: $primary-color;
$menu-item-font-weight-active: 400;
$menu-item-active-underline: true;

$menu-item-color-active-hover: $light-gray;
$menu-item-background-active-hover: $primary-color;

/*#endregion */

#pnlMenuMain {
    @include custom-menu;

    .top-bar {
        @include top-bar-container;
        /*.top-bar-right{
            .menu{
                li{
                    border-bottom: 1px solid transparent;
                }
            }
        }*/
    }

    background-color: $light-gray;

    li {
        a {
            position: relative;

            &:after {
                @include pseudo();
                width: 70%;
                height: 1px;
                background-color: $menu-item-color;
                margin-top: 5px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &:hover, &.active {
            a {
                &:after {
                    background-color: $menu-item-color-hover;
                }
            }
        }
    }
}

/*#endregion */

/*#region MENU SMALL */
$menu-overlap: false;

/*#region Variabili Title Bar*/

$titlebar-height: 60px;
$titlebar-padding: 0 0.5rem;

$titlebar-background: $secondary-color;
$titlebar-expand-background: scale-color($titlebar-background, $lightness: -2%);

$titlebar-icon-color: scale-color($primary-color, $lightness: 20%);
$titlebar-icon-color-hover: $primary-color;
$titlebar-icon-color-expand: scale-color($primary-color, $lightness: 20%);
/*#endregion*/

/*#region Variabili dropdown*/
$dropdow-padding: 0;
$dropdown-background: scale-color($secondary-color, $lightness: 70%);

$dropdown-item-display: block; //inline-block;

$menu-item-padding: 0.5rem 0.6rem;
$menu-item-margin: 0.4rem 0.5rem;

$menu-item-font-size: rem-calc(18);
$menu-item-text-transform: none;
$menu-item-font-weight: normal;

$menu-item-color: scale-color($primary-color, $lightness:20%);
$menu-item-background: transparent;

$menu-item-color-hover: $anchor-color; //$white;
$menu-item-background-hover: transparent; //scale-color($primary-color, $lightness:10%);
$menu-item-hover-underline: true;

$menu-item-color-active: $primary-color; //$white;
$menu-item-background-active: transparent; //$primary-color;
$menu-item-font-weight-active: normal;
$menu-item-active-underline: true;

$menu-item-color-active-hover: $primary-color; //$white;
$menu-item-background-active-hover: transparent; //scale-color($primary-color, $lightness:10%);
/*#endregion*/

/*#region Variabili icone*/
$menu-icons-size: 2rem; /*.5*/
$menu-icons-padding: 0.5rem 0;
$menu-icons-margin: 0.5rem 0;
$menu-icons-color: scale-color($primary-color, $lightness:20%); //$primary-color;
$menu-icons-background: transparent;
$menu-icons-color-hover: $primary-color; //scale-color($primary-color, $lightness:20%);
$menu-icons-background-hover: transparent;
/*#endregion*/

.menu-mobile {
    @include dropdown-small;
}

/*#endregion */

/*#region LANGUAGE REVEAL (small menu)*/

/*#region Variabili*/

$reveal-background: $white;

$menu-item-padding: 0.2rem; //swap&adjust margin/padding if active is underline
$menu-item-margin: 0.5rem;

$menu-item-font-size: rem-calc(14);
$menu-item-text-transform: uppercase;
$menu-item-font-weight: normal;

$menu-item-color: $primary-color;
$menu-item-background: transparent;

$menu-item-color-hover: $primary-color;
$menu-item-background-hover: transparent;
$menu-item-hover-underline: true;

$menu-item-color-active: $primary-color;
$menu-item-background-active: transparent;
$menu-item-font-weight-active: $bold;
$menu-item-active-underline: true;

$menu-item-color-active-hover: $primary-color;
$menu-item-background-active-hover: transparent;

/*#endregion */

#pnlLang.reveal {

    @include reveal-modal-base;
    @include custom-menu;
}

/*#endregion */

/*#region SEARCH REVEAL */

/*#region Variabili*/

$search-height: 50px;
$search-font-size: 2rem;


$search-icon-padding: 3px;

$search-icon-size: 2.5rem;
$search-icon-color: $primary-color;
$search-icon-background: transparent;

$search-icon-color-hover: scale-color($primary-color, $lightness:20%);
$search-icon-background-hover: transparent;

$search-show-input-border: false;

/*#endregion*/

#searchReveal {
    @include custom-search;
}

/*#endregion*/

/*#region LOGO */

//logo medium and large
#menu-section {
    .logo {
        /*&#lnkLogoMediumLarge {
            @include position($position:absolute, $top:50%);
            transform: translateY(-50%);
        }*/
        img {
            max-height: rem-calc(50);

            @include breakpoint(medium) {
                max-height: rem-calc(80);
                //margin: rem-calc(8 0 );
            }
        }
    }
}

/*#endregion */

/*#region Custom MENU css*/

#menu-section {
    position: relative;

    .menu-mobile {

        .title-bar {
            .top-bar-left {
                max-width: 70%;

                img {
                    max-height: 75%;
                }
            }
        }

        .icons {
            .multilang {
                list-style: none;
                margin: 0;

                .active a{
                    text-transform: uppercase;
                    border: none;
                    font-weight: $bold;
                }
            }
        }

        #lnkMultiLangSmall {
            font-size: 80% !important;
            text-transform: uppercase !important;
        }
    }
}

.reveal.header {
    min-height: unset;
    height: unset;

    @include breakpoint(small) {
        max-width: 90%;
        margin: auto;
    }
}

#pnlLang {
    text-align: center;

    a {
        display: inline-block !important;
    }
}

#pnlMenuUtility {
    .lang {
        line-height: 0;

        a {
            display: inline-block;
        }

        &:nth-child(2) {

            &:before {
                content: "/";
                color: $white;
            }
        }
    }
}
/*#endregion*/
